import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLogOut } from "../../utils/helpers";
import { LogoutIcon } from "../../components/common/images";
import { general } from "../../components/common/constants";

const SideDrawer = ({ isOpen, onClose }) => {
  const logout = useLogOut();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { pathname } = useLocation();

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };
  return (
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bgColor="#086375" color="#fff">
        <DrawerBody p={0} overflowY="scroll">
          <Flex h="100vh" py="40px" px="24px" flexDir="column">
            <Flex pb="58px" justifyContent="space-between" align="center">
              <Box>
                <Text
                  fontSize="24px"
                  color="#fff"
                  fontWeight={900}
                  fontFamily="Cooper"
                >
                  Managr
                </Text>
              </Box>
            </Flex>

            <Box>
              {general?.map((item, i) => {
                return (
                  <VStack
                    key={i}
                    align="stretch"
                    mb={pathname.includes(item?.path) ? "5px" : "unset"}
                    className={!pathname.includes(item?.path) && "parent_nav"}
                  >
                    <Flex
                      align="center"
                      w="full"
                      py="12px"
                      px="10px"
                      mb="12px"
                      fontSize="15px"
                      lineHeight="100%"
                      cursor="pointer"
                      onClick={() => {
                        navigate(item.path);
                        onClose();
                      }}
                      bg={pathname.includes(item.path) ? "#fff" : "transparent"}
                      color={pathname.includes(item.path) ? "#086375" : "#fff"}
                      fontWeight={500}
                      transition=".3s ease-in-out"
                      _hover={{
                        bg: pathname.includes(item.path) ? "" : "#fff",
                        color: pathname.includes(item.path) ? "" : "#086375",
                      }}
                      borderRadius={4}
                      gap="8px"
                      position="relative"
                    >
                      <Box className="hovered_image">{item.sec}</Box>

                      <Box className="initial_image">
                        {pathname.includes(item.path) ? item.sec : item.icon}
                      </Box>

                      <Text>{item.name}</Text>
                    </Flex>
                  </VStack>
                );
              })}
            </Box>

            <Box mt="auto">
              <Flex
                fontSize="15px"
                mt="60px"
                cursor="pointer"
                onClick={action}
                w="fit-content"
                align="center"
                gap="8px"
                px="10px"
                fontWeight={500}
              >
                {isLoading ? (
                  <Flex gap="5px" color="red" align="center" fontWeight={500}>
                    <Spinner size="sm" /> <Text>Logging Out</Text>
                  </Flex>
                ) : (
                  <Flex gap="12px" align="center" color="#fff">
                    <Text>Log Out</Text> <LogoutIcon />
                  </Flex>
                )}
              </Flex>
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideDrawer;
