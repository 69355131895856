import React from "react";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const StaffBack = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
    sessionStorage.removeItem("edit");
  };

  return (
    <Flex
      align="center"
      fontWeight="500"
      lineHeight="100%"
      color="#086375"
      cursor="pointer"
      gap="8px"
      onClick={goBack}
    >
      <IoIosArrowBack size={20} />
    </Flex>
  );
};

export default StaffBack;
