export const formatDate = (date, fallback = "") => {
  if (!date) return fallback;

  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const getStartOfWeek = (date) => {
  const start = new Date(date);
  const day = start.getDay();
  const diff = start.getDate() - day;
  start.setDate(diff);
  start.setHours(0, 0, 0, 0);
  return start;
};

export const getStartOfYear = (date) => {
  const currentMonth = date.getMonth(); // Get the current month (0 = January, ..., 11 = December)
  date.setMonth(currentMonth - 12); // Subtract one month
  return new Date(date.setDate(1)); // Set the date to the 1st of the previous month
};

export const getEndOfWeek = (date) => {
  const end = new Date(date);
  const day = end.getDay();
  const diff = end.getDate() + (6 - day);
  end.setDate(diff);
  end.setHours(23, 59, 59, 999);
  return end;
};

export const convertToDateTimeString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const formatNewDate = (date, fallback = "", withTime = false) => {
  if (!date) return fallback;

  const formattedDate = new Date(date);

  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");

  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");
  // const seconds = formattedDate.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${withTime ? `${hours}:${minutes}` : ""}`;
};

export const formatNewDates = (date, fallback = "", withTime = false) => {
  const formattedDate = new Date(date);

  // Check if the date is valid
  if (isNaN(formattedDate)) return fallback;

  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");

  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${withTime ? `${hours}:${minutes}` : ""}`;
};

export const formatDateNewTime = (date, fallback = "") => {
  if (!date) return fallback;

  let formattedDate = new Date(date);

  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  let hours = formattedDate.getHours();
  hours = hours % 12;
  hours = hours ? hours : 12;

  return formattedDate
    .toLocaleString("default", options)
    .replace(/(\d{1,2}):(\d{2})/, `${hours}:$2 `);
};

export const formatDat = (date, fallback = "") => {
  if (!date) return fallback;

  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatFilterDate = (date, fallback = "", withTime = false) => {
  if (!date) return fallback;

  const formattedDate = new Date(date);

  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");

  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");
  // const seconds = formattedDate.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}${withTime ? `${hours}:${minutes}` : ""}`;
};

export const convertDate = (originalDate) => {
  const currentDate = new Date(originalDate);

  // Extract components
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const hour = currentDate.getHours();
  const minute = currentDate.getMinutes();
  const second = currentDate.getSeconds();
  const millisecond = currentDate.getMilliseconds();

  // Create a new date
  const newDate = new Date(
    Date.UTC(year, month, day, hour, minute, second, millisecond),
  );

  // Format the date
  const formattedDate = newDate.toISOString();

  return formattedDate;
};

export const formatDates = (date, fallback = "") => {
  if (!date) return fallback;

  const formattedDate = new Date(date);

  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const trim = (str) => {
  return str?.length > 20 ? str.substring(0, 20) + "..." : str;
};

export const formatDateTime = (date, fallback = "") => {
  if (!date) return fallback;

  return new Date(date).toLocaleTimeString("default", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const trimID = (str) => {
  return str?.length > 30 ? str.substring(0, 30) + "..." : str;
};

export const useLogOut = () => {
  return () => {
    const clearAndRedirect = (path) => {
      localStorage.removeItem(path);
      sessionStorage.removeItem("staff");
      window.location.href = `/${path}/auth/login`;
    };

    const pathPrefix = location.pathname.match(/(admin)\//)?.[0] || "staff";
    const newPath = pathPrefix?.replace("/", "");

    if (pathPrefix) {
      clearAndRedirect(newPath);
    } else {
      clearAndRedirect("customer");
    }
  };
};

export const formatStatus = (status) => {
  return status
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

// filterUtils.js

const today = new Date();
const year = today.getFullYear();

export const generateFilterQuery = (filtArray) => {
  const convertedFilters = filtArray?.map((filterObj) => {
    return filterObj?.gte
      ? `filter=${filterObj?.title}||$gte||${formatFilterDate(filterObj?.gte)}T00:00:00`
      : filterObj?.lte
        ? `filter=${filterObj?.title}||$lte||${formatFilterDate(filterObj?.lte)}T23:59:59`
        : `filter=${filterObj?.title}||$${filterObj?.type || "cont"}||${filterObj?.filter}`;
  });

  return !filtArray?.length
    ? `filter=createdAt||$lte||${year}-12-31T23:59:59`
    : filtArray?.filter((item) => item?.gte)?.length > 0 &&
        filtArray?.filter((item) => item?.lte)?.length === 0
      ? `${convertedFilters?.join("&")}&filter=createdAt||$lte||${year}-12-31T23:59:59`
      : filtArray?.filter((item) => item?.gte)?.length === 0 &&
          filtArray?.filter((item) => item?.lte)?.length === 0
        ? `${convertedFilters?.join("&")}&filter=createdAt||$lte||${year}-12-31T23:59:59`
        : filtArray?.filter((item) => item?.gte)?.length === 0 &&
            filtArray?.filter((item) => item?.lte)?.length > 0
          ? `${convertedFilters?.join("&")}`
          : filtArray?.filter((item) => item?.gte)?.length &&
              filtArray?.filter((item) => item?.lte)?.length
            ? `${convertedFilters?.join("&")}`
            : convertedFilters?.join("&");
};
