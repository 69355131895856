import { useMutation, useQuery } from "react-query";
import {
  getAllStaffMessages,
  getLatestMessage,
  markAsRead,
} from "../api/messages";

export const useGetLatestMessage = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    "getLatestMessage",
    getLatestMessage,
    {
      ...options,
    },
  );

  return { data, isLoading, refetch };
};

export const useGetAllStaffMessages = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    "getAllStaffMessages",
    getAllStaffMessages,
    {
      ...options,
    },
  );

  return { data, isLoading, refetch };
};

export const useMarkAsRead = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(markAsRead, {
    mutationKey: "markAsRead",
    ...options,
  });
  return { mutate, isLoading, data };
};
