import axiosInstance from "../../axiosInstance";
import * as API from "../url";

export const getLatestMessage = async () => {
  const res = await axiosInstance.get(API.GET_LATES_UNREAD);
  return res.data;
};

export const getAllStaffMessages = async () => {
  const res = await axiosInstance.get(
    `${API.GET_STAFF_MESSAGES}?sort=createdAt,DESC`,
  );
  return res.data;
};

export const markAsRead = async (id) => {
  const response = await axiosInstance.patch(API.MARK_AS_READ(id));
  return response.data;
};
