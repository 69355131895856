import { lazy } from "react";
import WithSuspense from "../components/loader/WithSuspense";
import { PUBLIC_PATHS, PRIVATE_PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const {
  //STAFF
  LOGIN,
  NEW_PASS,
  NEW_LOGIN,
  NEW_USER,
  FORGOT_PASS,
  CODE_VERIFICATION,
  RESET_PASS,

  // ADMIN routes
  ADMIN_LOGIN,
} = PUBLIC_PATHS;

const {
  //STAFF
  DASHBOARD,
  PROFILE,
  SCHEDULE,
  LOANS,
  REQUEST_LOAN,
  LOAN_DETAILS,
  UPDATE_LOAN,
  LEAVE,
  REQUEST_LEAVE,
  LEAVE_DETAILS,
  UPDATE_LEAVE,
  MEDICAL,
  REQUEST_MEDICAL,
  MEDICAL_DETAILS,
  PERFORMANCE,
  PERFORMANCE_APPR_DETAILS,
  PERFORMANCE_REV,
  PERFORMANCE_REV_HISTORY,
  PERFORMANCE_REV_DETAILS,
  UPDATE_MED,

  // ADMIN

  ADMIN_DASHBOARD,
  ADMIN_PROFILE,
  ADMIN_EDIT_PROFILE,
  ADMIN_STAFF_PROFILE,
  ADMIN_ADD_PENALTY,
  ADMIN_ADD_REWARD,
  ADMIN_PERFORMANCE,
  ADMIN_ADMIN_PROFILE,
  ADMIN_LOAN,
  ADMIN_LOAN_DETAILS,
  ADMIN_LEAVE_MGT,
  ADMIN_ADD_LEAVE_MGT,
  ADMIN_MEDICAL_ASSISTANCE,
  ADMIN_MEDICAL_ASSISTANCE_DETAILS,
  ADMIN_ADD_MEDICAL_ASSISTANCE,
  ADMIN_STAFF_SCHEDULE,
  ADMIN_ADD_STAFF_SCHEDULE_LOCATION,
  ADMIN_EDIT_STAFF_SCHEDULE_LOCATION,
  ADMIN_STAFF_SCHEDULE_LOCATION,
  ADMIN_ADD_STAFF_SCHEDULE_STAFF,
  ADMIN_STAFF_SCHEDULE_LOCATION_WEEK,
  ADMIN_SETTINGS,
  ADMIN_ADD_DEPT,
  ADMIN_ADD_JOB,
  ADMIN_VIEW_DEPT,
  ADMIN_EDIT_DEPT,
  ADMIN_EDIT_JOB,
  ADMIN_VIEW_JOB,
  ADMIN_ADD_PERMISSION,
  ADMIN_VIEW_STAFF_ROLE,
  ADMIN_VIEW_PERMISSION,
  ADMIN_ADD_STAFF_ROLE,
  ADMIN_ADD_ROLE,
  ADMIN_EDIT_ROLE,
  ADMIN_PERFORMANCE_APPR_DETAILS,
  ADMIN_PERFORMANCE_EDIT_PEN,
  ADMIN_PERFORMANCE_EDIT_REW,
} = PRIVATE_PATHS;

// STAFF PAGES
const Login = WithSuspense(
  lazy(() => import("../pages/Staff/Authentication/Login")),
);
const FirstLogin = WithSuspense(
  lazy(() => import("../pages/Staff/Authentication/FirstLogin")),
);
const NewUser = WithSuspense(
  lazy(() => import("../pages/Staff/Authentication/NewUser")),
);
const ForgotPassword = WithSuspense(
  lazy(() => import("../pages/Staff/Authentication/ForgotPassword")),
);
const CodeVerification = WithSuspense(
  lazy(() => import("../pages/Staff/Authentication/CodeVerification")),
);
const CreateNewPassword = WithSuspense(
  lazy(() => import("../pages/Staff/Authentication/CreateNewPassword")),
);
const Dashboard = WithSuspense(lazy(() => import("../pages/Staff/Dashboard")));
const Profile = WithSuspense(lazy(() => import("../pages/Staff/Profile")));
const Schedule = WithSuspense(lazy(() => import("../pages/Staff/Schedule")));
const Loans = WithSuspense(lazy(() => import("../pages/Staff/Loans")));
const RequestLoan = WithSuspense(
  lazy(() => import("../pages/Staff/Loans/RequestLoan")),
);
const UpdateLoan = WithSuspense(
  lazy(() => import("../pages/Staff/Loans/UpdateLoan")),
);
const LoanDetails = WithSuspense(
  lazy(() => import("../pages/Staff/Loans/LoanDetails")),
);
const Leave = WithSuspense(lazy(() => import("../pages/Staff/Leave")));
const RequestLeave = WithSuspense(
  lazy(() => import("../pages/Staff/Leave/RequestLeave")),
);
const UpdateLeave = WithSuspense(
  lazy(() => import("../pages/Staff/Leave/UpdateLeave")),
);
const LeaveDetails = WithSuspense(
  lazy(() => import("../pages/Staff/Leave/LeaveDetails")),
);
const Medical = WithSuspense(lazy(() => import("../pages/Staff/Medical")));
const RequestMedical = WithSuspense(
  lazy(() => import("../pages/Staff/Medical/RequestMedical")),
);
const Performance = WithSuspense(
  lazy(() => import("../pages/Staff/Performance")),
);
const ApppraisalDetails = WithSuspense(
  lazy(() => import("../pages/Staff/Performance/ApppraisalDetails")),
);
const ReviewDetails = WithSuspense(
  lazy(() => import("../pages/Staff/Performance/ReviewDetails")),
);
const ReviewHistory = WithSuspense(
  lazy(() => import("../pages/Staff/Performance/ReviewHistory")),
);
const Review = WithSuspense(
  lazy(() => import("../pages/Staff/Performance/Review")),
);
const UpdateMed = WithSuspense(
  lazy(() => import("../pages/Staff/Medical/UpdateMed")),
);
const MedicalDetails = WithSuspense(
  lazy(() => import("../pages/Staff/Medical/MedicalDetails")),
);
const StaffMsgs = WithSuspense(lazy(() => import("../pages/Staff/Messages")));

// ADMIN PAGES
const AdminDashboard = WithSuspense(
  lazy(() => import("../pages/Admin/Dashboard")),
);

const AdminLogin = WithSuspense(
  lazy(() => import("../pages/Admin/Auth/Login")),
);

const AdminProfile = WithSuspense(lazy(() => import("../pages/Admin/Profile")));

const AdminUpdateProfile = WithSuspense(
  lazy(() => import("../pages/Admin/Profile/EditProfile")),
);

const AdminStaffProfile = WithSuspense(
  lazy(() => import("../pages/Admin/StaffProfile")),
);

const AdminAddStaffProfile = WithSuspense(
  lazy(() => import("../pages/Admin/StaffProfile/AddStaff")),
);

const AdminAddStaffProfileDetails = WithSuspense(
  lazy(() => import("../pages/Admin/StaffProfile/StaffProfileDetails")),
);

const AdminAdminProfile = WithSuspense(
  lazy(() => import("../pages/Admin/AdminProfile")),
);

const AdminAddAdminProfile = WithSuspense(
  lazy(() => import("../pages/Admin/AdminProfile/AddAdmin")),
);

const AdminAdminProfileDetails = WithSuspense(
  lazy(() => import("../pages/Admin/AdminProfile/AdminProfileDetails")),
);

const AdminLoan = WithSuspense(lazy(() => import("../pages/Admin/Loans")));

const AdminLoanDetails = WithSuspense(
  lazy(() => import("../pages/Admin/Loans/LoanDetails")),
);

const AdminPerformanceDetails = WithSuspense(
  lazy(
    () => import("../pages/Admin/Performance/Appraisals/PerformanceDetails"),
  ),
);

const AdminPerformanceReward = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Appraisals/EditReward")),
);

const AdminPerformancePenalty = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Appraisals/EditPenalty")),
);

const AdminAddRewardPerformance = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Appraisals/AddReward")),
);

const AdminAddPenaltyPerformance = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Appraisals/AddPenalty")),
);

const AdminLeaveMgt = WithSuspense(
  lazy(() => import("../pages/Admin/LeaveMgt")),
);

const AdminAddLoan = WithSuspense(
  lazy(() => import("../pages/Admin/Loans/AddLoan")),
);

const AdminAddLeaveMgt = WithSuspense(
  lazy(() => import("../pages/Admin/LeaveMgt/AddLeave")),
);

const AdminLeaveMgtDetails = WithSuspense(
  lazy(() => import("../pages/Admin/LeaveMgt/LeaveMgtDetails")),
);

const AdminMedicalAssistance = WithSuspense(
  lazy(() => import("../pages/Admin/MedicalAssistance")),
);

const AdminMedicalAssistanceDetails = WithSuspense(
  lazy(() => import("../pages/Admin/MedicalAssistance/MedDetails")),
);

const AdminAddMed = WithSuspense(
  lazy(() => import("../pages/Admin/MedicalAssistance/AddMed")),
);

const AdminMessage = WithSuspense(
  lazy(() => import("../pages/Admin/Messages")),
);

const AdminMessageDetails = WithSuspense(
  lazy(() => import("../pages/Admin/Messages/ViewMessage")),
);

const AdminMessageUpdate = WithSuspense(
  lazy(() => import("../pages/Admin/Messages/UpdateMessage")),
);

const AdminAddMessage = WithSuspense(
  lazy(() => import("../pages/Admin/Messages/AddMessage")),
);

const AdminStaffSchedule = WithSuspense(
  lazy(() => import("../pages/Admin/StaffSchedule")),
);

const AdminAddStaffScheduleLocation = WithSuspense(
  lazy(() => import("../pages/Admin/StaffSchedule/AddScheduleLocation")),
);

const AdminEditStaffScheduleLocation = WithSuspense(
  lazy(() => import("../pages/Admin/StaffSchedule/EditScheduleLocation")),
);

const AdminViewScheduleLocation = WithSuspense(
  lazy(() => import("../pages/Admin/StaffSchedule/ViewLocationSchedule")),
);

const AdminViewScheduleLocationWeek = WithSuspense(
  lazy(() => import("../pages/Admin/StaffSchedule/WeekLocation")),
);

const AdminAddStaffScheduleStaff = WithSuspense(
  lazy(() => import("../pages/Admin/StaffSchedule/AddScheduleStaff")),
);

const AdminSettings = WithSuspense(
  lazy(() => import("../pages/Admin/Settings")),
);

const AdminAddJob = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/AddJob")),
);

const AdminAddPermission = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/AddPermission")),
);

const AdminViewPermission = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/EditPermission")),
);

const AdminAddStaffRole = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/AddStaffRole")),
);

const AdminViewStaffRole = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/ViewStaffRole")),
);

const AdminAddRoles = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/AddRole")),
);

const AdminEditRoles = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/EditRole")),
);
const AdminAddDept = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/AddDept")),
);
const AdminViewDept = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/ViewDept")),
);
const AdminEditDept = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/EditDept")),
);
const AdminEditJob = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/EditJob")),
);
const AdminViewJob = WithSuspense(
  lazy(() => import("../pages/Admin/Settings/ViewJob")),
);
const AdminSetup = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Setup/Setup")),
);
const AdminAddCat = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Setup/AddCat")),
);
const AdminAddItem = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Setup/AddItem")),
);
const AdminProcureOverview = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Overview")),
);
const AdminProcureCategory = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Category")),
);
const AdminProcureProcurement = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Procurements")),
);
const AdminAddProcurement = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Procurements/Create")),
);
const AdminViewProcurement = WithSuspense(
  lazy(
    () =>
      import("../pages/Admin/Inventory/Procure/Procurements/ViewProcurement"),
  ),
);
const AdminProcureVendor = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Vendor")),
);
const AdminProcureMaintenance = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Maintenance")),
);
const AdminProcureItems = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Items")),
);
const AdminReports = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Reports")),
);
const AdminReq = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Requisitions")),
);
const AdminAddReq = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Requisitions/CreateReq")),
);
const AdminEditReq = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Requisitions/UpdateReq")),
);
const AdminViewReq = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Requisitions/ViewReq")),
);
const AdminAddVendor = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Vendor/Create")),
);
const AdminViewVendor = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Vendor/ViewVendor")),
);
const AdminViewCategory = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Category/ViewCategory")),
);
const AdminEditVendor = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Vendor/EditVendor")),
);
const AdminViewItem = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Items/ViewItem")),
);
const AdminFlagItem = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Issuance/Flag")),
);
const AdminEditItem = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Procure/Items/UpdateItem")),
);
const AdminIssuance = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Issuance")),
);
const AdminAddIssuance = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Issuance/Create")),
);
const AdminUpdateIssuance = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Issuance/Update")),
);
const AdminViewIssuance = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Issuance/View")),
);
const AdminReport = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Report")),
);
const AdminMaintain = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Maintain")),
);
const AdminViewMaintain = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Maintain/View")),
);
const AdminSendMaintain = WithSuspense(
  lazy(() => import("../pages/Admin/Inventory/Maintain/Send")),
);

const AdminPerfOverview = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Overview")),
);

const AdminPerfSetup = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Setup")),
);

const AdminPerfSetupKpi = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Setup/CreateKpi")),
);

const AdminPerfSetupQuestion = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Setup/CreateQuestion")),
);

const AdminPerfEditQuestion = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Setup/EditQuestion")),
);

const AdminPerfTeams = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Teams")),
);

const AdminAddPerfTeams = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Teams/Create")),
);

const AdminPerfReviews = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Reviews")),
);

const AdminPerfReviewDetails = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Reviews/ReviewDetails")),
);

const AdminPerfAppraisals = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Appraisals")),
);

const AdminPerfCycles = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Cycles")),
);

const AdminPerfViewTeam = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Teams/View")),
);

const AdminPerfUpdateTeam = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Teams/Update")),
);

const AdminPerfCyclesDetails = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Cycles/View")),
);

const AdminAddPerfCycles = WithSuspense(
  lazy(() => import("../pages/Admin/Performance/Cycles/Create")),
);

export const PUBLIC_ROUTES = [
  // STAFF ROUTES
  { path: LOGIN, element: <Login /> },
  { path: NEW_LOGIN, element: <FirstLogin /> },
  { path: NEW_USER, element: <NewUser /> },
  { path: FORGOT_PASS, element: <ForgotPassword /> },
  { path: CODE_VERIFICATION, element: <CodeVerification /> },
  { path: RESET_PASS, element: <CreateNewPassword /> },
  { path: NEW_PASS, element: <CreateNewPassword /> },

  // ADMIN ROUTES
  { path: ADMIN_LOGIN, element: <AdminLogin /> },

  {
    path: "*",
    element: (
      <Navigate
        to={
          location.pathname.includes("admin")
            ? "/admin/auth/login"
            : "/staff/auth/login"
        }
        replace
      />
    ),
  },
];

export const PRIVATE_ROUTES = [
  // STAFF
  { path: DASHBOARD, element: <Dashboard /> },
  { path: PROFILE, element: <Profile /> },
  { path: SCHEDULE, element: <Schedule /> },
  { path: LOANS, element: <Loans /> },
  { path: REQUEST_LOAN, element: <RequestLoan /> },
  { path: LOAN_DETAILS, element: <LoanDetails /> },
  { path: LEAVE, element: <Leave /> },
  { path: MEDICAL, element: <Medical /> },
  { path: REQUEST_LEAVE, element: <RequestLeave /> },
  { path: LEAVE_DETAILS, element: <LeaveDetails /> },
  { path: REQUEST_MEDICAL, element: <RequestMedical /> },
  { path: PERFORMANCE, element: <Performance /> },
  { path: PERFORMANCE_APPR_DETAILS, element: <ApppraisalDetails /> },
  { path: PERFORMANCE_REV_DETAILS, element: <ReviewDetails /> },
  { path: PERFORMANCE_REV_HISTORY, element: <ReviewHistory /> },
  { path: PERFORMANCE_REV, element: <Review /> },
  { path: MEDICAL_DETAILS, element: <MedicalDetails /> },
  { path: PRIVATE_PATHS.STAFF_MESSAGES, element: <StaffMsgs /> },
  { path: UPDATE_LEAVE, element: <UpdateLeave /> },
  { path: UPDATE_LOAN, element: <UpdateLoan /> },
  { path: UPDATE_MED, element: <UpdateMed /> },

  // ADMIN
  {
    path: ADMIN_STAFF_PROFILE,
    element: <AdminStaffProfile />,
  },
  {
    path: ADMIN_ADMIN_PROFILE,
    element: <AdminAdminProfile />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_ADD_STAFF,
    element: <AdminAddStaffProfile />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_ADD_ADMIN,
    element: <AdminAddAdminProfile />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_STAFF_PROFILE_DETAILS,
    element: <AdminAddStaffProfileDetails />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_ADMIN_PROFILE_DETAILS,
    element: <AdminAdminProfileDetails />,
  },

  {
    path: ADMIN_DASHBOARD,
    element: <AdminDashboard />,
  },

  {
    path: ADMIN_PROFILE,
    element: <AdminProfile />,
  },

  {
    path: ADMIN_SETTINGS,
    element: <AdminSettings />,
  },

  {
    path: ADMIN_EDIT_PROFILE,
    element: <AdminUpdateProfile />,
  },

  {
    path: ADMIN_LEAVE_MGT,
    element: <AdminLeaveMgt />,
  },

  {
    path: ADMIN_ADD_LEAVE_MGT,
    element: <AdminAddLeaveMgt />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_ADD_LOAN,
    element: <AdminAddLoan />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_LEAVE_MGT_DEETAILS,
    element: <AdminLeaveMgtDetails />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_SETUP,
    element: <AdminSetup />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_ADD_ITEM,
    element: <AdminAddItem />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_ADD_CAT,
    element: <AdminAddCat />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_PROCURE_OVERVIEW,
    element: <AdminProcureOverview />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_OVERVIEW,
    element: <AdminPerfOverview />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_SETUP,
    element: <AdminPerfSetup />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_SETUP_CREATE_KPI,
    element: <AdminPerfSetupKpi />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_SETUP_CREATE_QUESTION,
    element: <AdminPerfSetupQuestion />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_SETUP_EDIT_QUESTION,
    element: <AdminPerfEditQuestion />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_TEAMS,
    element: <AdminPerfTeams />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_TEAMS_CREATE,
    element: <AdminAddPerfTeams />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_REVIEWS,
    element: <AdminPerfReviews />,
  },
  {
    path: PRIVATE_PATHS.ADMIN_PERF_REVIEW_DETAILS,
    element: <AdminPerfReviewDetails />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_APPRAISALS,
    element: <AdminPerfAppraisals />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_CYCLES,
    element: <AdminPerfCycles />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_CYCLES_DETAILS,
    element: <AdminPerfCyclesDetails />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_TEAMS_UPDATE,
    element: <AdminPerfUpdateTeam />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_TEAMS_VIEW,
    element: <AdminPerfViewTeam />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_PERF_CYCLES_CREATE,
    element: <AdminAddPerfCycles />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_PROCURE_CATEGORY,
    element: <AdminProcureCategory />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_PROCURE_PROCUREMENT,
    element: <AdminProcureProcurement />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_PROCURE_PROCUREMENT_CREATE,
    element: <AdminAddProcurement />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_VIEW_PROCURE_PROCUREMENT,
    element: <AdminViewProcurement />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_VIEW_CATEGORY,
    element: <AdminViewCategory />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_PROCURE_VENDOR,
    element: <AdminProcureVendor />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_PROCURE_MAINTENANCE,
    element: <AdminProcureMaintenance />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_ADD_VENDOR,
    element: <AdminAddVendor />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_VIEW_VENDOR,
    element: <AdminViewVendor />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_EDIT_VENDOR,
    element: <AdminEditVendor />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_VIEW_ITEM,
    element: <AdminViewItem />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_FLAG_ITEM,
    element: <AdminFlagItem />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_EDIT_ITEM,
    element: <AdminEditItem />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_REQUISITION,
    element: <AdminReq />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_REQUISITION_DETAILS,
    element: <AdminViewReq />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_REQUISITION_CREATE,
    element: <AdminAddReq />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_REQUISITION_UPDATE,
    element: <AdminEditReq />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_REPORTS,
    element: <AdminReports />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_PROCURE_ITEMS,
    element: <AdminProcureItems />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_ISSUANCE,
    element: <AdminIssuance />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_ISSUANCE_CREATE,
    element: <AdminAddIssuance />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_ISSUANCE_DETAILS,
    element: <AdminViewIssuance />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_ISSUANCE_UPDATE,
    element: <AdminUpdateIssuance />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_REPORT,
    element: <AdminReport />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_MAINTAIN,
    element: <AdminMaintain />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_MAINTAIN_VIEW,
    element: <AdminViewMaintain />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_INVENTORY_MAINTAIN_SEND,
    element: <AdminSendMaintain />,
  },

  {
    path: ADMIN_LOAN,
    element: <AdminLoan />,
  },

  {
    path: ADMIN_LOAN_DETAILS,
    element: <AdminLoanDetails />,
  },

  {
    path: ADMIN_PERFORMANCE,
    element: <AdminPerfOverview />,
  },

  {
    path: ADMIN_PERFORMANCE_APPR_DETAILS,
    element: <AdminPerformanceDetails />,
  },

  {
    path: ADMIN_PERFORMANCE_EDIT_PEN,
    element: <AdminPerformancePenalty />,
  },

  {
    path: ADMIN_PERFORMANCE_EDIT_REW,
    element: <AdminPerformanceReward />,
  },

  {
    path: ADMIN_ADD_REWARD,
    element: <AdminAddRewardPerformance />,
  },

  {
    path: ADMIN_ADD_PENALTY,
    element: <AdminAddPenaltyPerformance />,
  },

  {
    path: ADMIN_MEDICAL_ASSISTANCE,
    element: <AdminMedicalAssistance />,
  },

  {
    path: ADMIN_ADD_DEPT,
    element: <AdminAddDept />,
  },

  {
    path: ADMIN_VIEW_DEPT,
    element: <AdminViewDept />,
  },

  {
    path: ADMIN_EDIT_DEPT,
    element: <AdminEditDept />,
  },

  {
    path: ADMIN_EDIT_JOB,
    element: <AdminEditJob />,
  },

  {
    path: ADMIN_VIEW_JOB,
    element: <AdminViewJob />,
  },

  {
    path: ADMIN_ADD_JOB,
    element: <AdminAddJob />,
  },

  {
    path: ADMIN_ADD_PERMISSION,
    element: <AdminAddPermission />,
  },

  {
    path: ADMIN_VIEW_PERMISSION,
    element: <AdminViewPermission />,
  },

  {
    path: ADMIN_ADD_STAFF_ROLE,
    element: <AdminAddStaffRole />,
  },

  {
    path: ADMIN_VIEW_STAFF_ROLE,
    element: <AdminViewStaffRole />,
  },

  {
    path: ADMIN_ADD_ROLE,
    element: <AdminAddRoles />,
  },

  {
    path: ADMIN_EDIT_ROLE,
    element: <AdminEditRoles />,
  },

  {
    path: ADMIN_MEDICAL_ASSISTANCE_DETAILS,
    element: <AdminMedicalAssistanceDetails />,
  },

  {
    path: ADMIN_ADD_MEDICAL_ASSISTANCE,
    element: <AdminAddMed />,
  },
  {
    path: PRIVATE_PATHS.ADMIN_MESSAGE_DETAILS,
    element: <AdminMessageDetails />,
  },
  {
    path: PRIVATE_PATHS.ADMIN_MESSAGE_UPDATE,
    element: <AdminMessageUpdate />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_ADD_MESSAGE,
    element: <AdminAddMessage />,
  },

  {
    path: PRIVATE_PATHS.ADMIN_MESSAGE,
    element: <AdminMessage />,
  },

  {
    path: ADMIN_STAFF_SCHEDULE,
    element: <AdminStaffSchedule />,
  },

  {
    path: ADMIN_ADD_STAFF_SCHEDULE_LOCATION,
    element: <AdminAddStaffScheduleLocation />,
  },

  {
    path: ADMIN_EDIT_STAFF_SCHEDULE_LOCATION,
    element: <AdminEditStaffScheduleLocation />,
  },

  {
    path: ADMIN_STAFF_SCHEDULE_LOCATION,
    element: <AdminViewScheduleLocation />,
  },

  {
    path: ADMIN_STAFF_SCHEDULE_LOCATION_WEEK,
    element: <AdminViewScheduleLocationWeek />,
  },

  {
    path: ADMIN_ADD_STAFF_SCHEDULE_STAFF,
    element: <AdminAddStaffScheduleStaff />,
  },
  {
    path: "*",
    element: (
      <Navigate
        to={
          location.pathname.includes("admin")
            ? "/admin/dashboard"
            : "/staff/dashboard"
        }
        replace
      />
    ),
  },
];
